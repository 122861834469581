export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_Google_Analytics
export const GA_AD = process.env.NEXT_PUBLIC_Google_Ads


// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
    try{
        window.gtag('config', GA_TRACKING_ID, {
            page_path: url,
        })

        window.gtag('event', 'page_view', {
            'send_to': `${GA_TRACKING_ID}`,
            'value': '',
            'items': [{
            'id': '',
            'google_business_vertical': 'retail'
            }]
        });

    }catch(err){
        console.log(err)
    }
}


// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
    try{
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value,
        })
        
    }catch(err){
        console.log(err)
    }
}