"use client"

/* eslint-disable @next/next/no-img-element */
import { FBPixelImage } from "./Facebook";


function PixelImage(){
    return(
        <>
            <FBPixelImage />

            <noscript>
                <img 
                    alt="bing_pixel" 
                    src="//bat.bing.com/action/0?ti=4073793&Ver=2" 
                    height="0" 
                    width="0" 
                    style={{display: "none"}} 
                />
            </noscript>

            <noscript>
                <img 
                    height="1" 
                    width="1" 
                    style={{display: "none"}}
                    alt="" 
                    src={`https://ct.pinterest.com/v3/?event=init&tid=2615666729486&noscript=1`}
                />
            </noscript>
        </>
    )
}

export default PixelImage;