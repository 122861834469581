import { useEffect } from "react";
import { setCookie } from 'nookies'



export default function useUTM(){
    useEffect(() => {
        const utmCookie = {};
      
        let utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content'];
        const searchParams = new URLSearchParams(window.location.search);

        utmParams.map((param) => {
           const paramValue = searchParams.get(param);

            if (paramValue) {
                utmCookie[param] = paramValue;
            }
        })

        if (searchParams.get('utm_source') && searchParams.get('utm_source') !== "") {
            setCookie({}, "TEAK_UTMParams", JSON.stringify(utmCookie), { secure: true, maxAge: 60 * 60 * 24 * 365, path: "/", domain: '.authenteak.com' });
        }

    }, [])
}