"use client"

import Script from 'next/script'
import { useContext, useEffect } from 'react'
import { FB_PIXEL_ID } from '../Facebook/fbPixel'
import * as fbq from 'thirdparty/Facebook/fbPixel';
import * as gtag from 'thirdparty/Google/gtag'
import { usePathname } from 'next/navigation';
import Zendesk from 'thirdparty/Zendesk';
import NostoGraphql from 'thirdparty/Nosto/Graphql.service';
import CreateNostoSession from 'thirdparty/Nosto/mutations/CreateNostoSession';
import { LocalStorage, storageKeys } from 'services/LocalStorage.service';
import { GlobalContext } from 'contexts/GlobalContext';
import { parseCookies, setCookie } from 'nookies'
import { mobileCookieOptions } from 'lib/Constants';
import useUTM from 'custom_hooks/useUTM';
import nookies from 'nookies';
import { v4 as uuidv4 } from 'uuid';


const nostoGraphql = new NostoGraphql()


export default function ScriptManager(){
    const asPath = usePathname()
    const [ _, dispatch ] = useContext(GlobalContext)
    const nonce = /*headers().get('x-nonce')*/ null

    // UTM Tracking
    useUTM()

    
    function setSession(nostoCustomerId){
        // save so we don't fetch again
        LocalStorage.setStorage(storageKeys.nosto.sessionID, nostoCustomerId, "local")
                        
        // cookie is used for secure.at.com
        setCookie(null, storageKeys.nosto.sessionID, nostoCustomerId, { ...mobileCookieOptions });

        // notify nostos that we can fetch nosto data
        dispatch({
            type: "nostoSession",
            data: nostoCustomerId
        })
    }


    /**
     * First Party tracking
     */

    useEffect(() => {
        // double check that this is actually a touch device, if not we set it as desktop, no touch
		// sometimes get a false positive on the mobile
        const isTouchCapable = (window.matchMedia && window.matchMedia('(any-pointer: coarse)').matches);
        
        setCookie(null, storageKeys.isMobile, isTouchCapable, { ...mobileCookieOptions });


        // Search Tracking
        const cookies = nookies.get();
        const token = cookies[storageKeys.searchUserToken];

        if (!token) {
            nookies.set(null, storageKeys.searchUserToken, uuidv4(), {
                maxAge: 30 * 24 * 60 * 60, // 30 days
                path: '/',
            });
        }

    }, [])




    /* 
    * Init Tracking for 3rd Party Scripts
    */

    useEffect(() => {
        try{
            fbq.pageview();

            
            if( window?._rejoiner ){
                _rejoiner.push(['addPageView', {
                    'url': process.env.NEXT_PUBLIC_siteUrl + asPath
                }]);
            }

            // Create Nosto Session
            const cookies = parseCookies();
            const nostoSession = cookies.TEAK_nostoSessionId;
            

            if( !nostoSession ){
                const createNostoSession = CreateNostoSession()

                nostoGraphql.post(createNostoSession).then(response => {
                    if( response?.data?.newSession ){
                        setSession(response?.data?.newSession)
                    }
                })

            }else{
                setSession(nostoSession)
            }

        }catch(err){ 
            console.log(err) 
        }

    }, [])
    
    
    useEffect(() => {
        try{
            gtag.pageview(asPath)
            fbq.pageview()

            if( window?._rejoiner ){
                _rejoiner.push(['addPageView', {
                    'url': process.env.NEXT_PUBLIC_siteUrl + asPath
                }]);
            }
            
        }catch(err){ 
            console.log(err) 
        }

	}, [ asPath ])



    
    return(
        <>
            {/* Third Party Scripts 
            <Script 
                id="tidio"
                src="//code.tidio.co/om8t0xjj96rag9hgjnntelrfupih7biu.js" 
                strategy="afterInteractive" 
                nonce={nonce} 
            />
*/}

            <Script id="rejoiner" strategy="afterInteractive" nonce={nonce}>
            {`
                var _rejoiner = window._rejoiner || [];
                _rejoiner.push(['setAccount', '${process.env.NEXT_PUBLIC_rejoiner_site_id}']);
                _rejoiner.push(['setDomain', 'authenteak.com']);
                _rejoiner.push(['trackPageView', false]);

                (function() {
                var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true;
                s.src = '//cdn.rejoiner.com/js/v4/rj2.lib.js';
                s.nonce=${nonce};
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
                })();
            `}
            </Script>
           
            
            <Zendesk nonce={nonce} />

            
            <Script id="bing" strategy="afterInteractive" nonce={nonce}>
            {`
                (function(w,d,t,r,u){
                var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"4073793"};
                o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.nonce=${nonce},n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;
                s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
                })(window,document,"script","//bat.bing.com/bat.js","uetq");
            `}
            </Script>



            <Script id="bingClarity" strategy="afterInteractive" nonce={nonce}>
            {`
                (function(c,l,a,r,i,t,y){
                c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                t=l.createElement(r);t.async=1;t.nonce=${nonce};t.src="https://www.clarity.ms/tag/"+i+"?ref=bwt";
                y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "bccqvijkpj");
            `}
            </Script>



            <Script id="facebookPixel" strategy="afterInteractive" nonce={nonce}>
            {`
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;t.nonce=${nonce};
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');

                fbq('init', ${FB_PIXEL_ID});
                fbq('track', 'PageView');
            `}
            </Script>



            <Script
                id="google-analytics"
				strategy="afterInteractive"
				src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                nonce={nonce}
			/>



			<Script id="gtag-init" strategy="afterInteractive" nonce={nonce}>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_TRACKING_ID}', {
                    page_path: window.location.pathname,
                });
            `}
            </Script>



            <Script id="googleAds" strategy='afterInteractive' nonce={nonce}>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${gtag.GA_AD}');
            `}
            </Script>



            <Script id="pinterest" strategy="afterInteractive" nonce={nonce}>
            {`
                !function(e){if(!window.pintrk){window.pintrk = function () {
                window.pintrk.queue.push(Array.prototype.slice.call(arguments))};
                var n=window.pintrk;n.queue=[],n.version="3.0";
                var t=document.createElement("script");t.async=!0,t.nonce=${nonce};t.src=e;
                var r=document.getElementsByTagName("script")[0];
                r.parentNode.insertBefore(t,r)}}("https://s.pinimg.com/ct/core.js");
                pintrk('load', '2615666729486', {em: ''});
                pintrk('page');
            `}
            </Script>
            
            <Script
                id="callrail-analytics"
				strategy="beforeInteractive"
				src="//cdn.callrail.com/companies/352183850/a81dfe069051c018185b/12/swap.js"
                nonce={nonce}
			/> 
        </>
    )
}