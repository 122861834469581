/**
*   Nosto Recommendation State Manager:
*/

export const InitialState = {
    init: false,
    isLoaded: false,
    placements: null
 };
 
 
 export const NostoReducer = ((state, action) => {
     switch(action.type){
        case "init": 
            return {
                ...state,
                init: action?.data
            };


        case "isLoaded": 
            return {
                ...state,
                isLoaded: action?.data
            };

            
        case "setRecommendations":
            return{
                ...state,
                placements: {
                    ...state.placements,
                    ...action?.data
                }
            }
 
         default: return state;
     }
 })