"use client"
/* eslint-disable react-hooks/exhaustive-deps */

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import { persistQueryClient } from 'react-query/persistQueryClient-experimental'
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental'
import { QueryClient, QueryClientProvider } from 'react-query';

/**
 * Persisting our queries for a session
 */

function LocalStoragePersistor({ children }){
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: { 
                cacheTime: 1000 * 60 * 60 * 24
            },
		},
	});

    const pathName = usePathname()

    useEffect(() => {
        const localStoragePersistor = createWebStoragePersistor({
            storage: window.sessionStorage,
            key: "TEAK_PERSISTED",
            throttleTime: 1000
        })
    
        persistQueryClient({
            queryClient,
            persistor: localStoragePersistor,
            maxAge: 1000 * 60 * 60 * 24
        })

    }, [ pathName ])
    

    return(
        <QueryClientProvider client={queryClient}>
            {children}
        </QueryClientProvider>
    )
}

export default LocalStoragePersistor;