export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID

export const pageview = () => {
  try{
    window.fbq('track', 'PageView')

  }catch(err){
    console.log(err)
  }
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name, options = {}) => {
  try{
    window.fbq('track', name, options)
    
  }catch(err){
    console.log(err)
  }
}