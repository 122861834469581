"use client"

/* eslint-disable @next/next/no-img-element */
import { FB_PIXEL_ID } from "./fbPixel";


function FBPixelImage(){
    return(
        <noscript>
            <img
                height="1"
                width="1"
                alt="facebook Pixel"
                style={{ display: 'none' }}
                src={`https://www.facebook.com/tr?id=${FB_PIXEL_ID}&ev=PageView&noscript=1`}
            />
        </noscript>
    )
}

export default FBPixelImage;