"use client"

/* eslint-disable react-hooks/exhaustive-deps */
import { GlobalContext } from "contexts/GlobalContext";
import { useReducer, useContext } from "react";
import { NostoContext } from "./NostoContext";
import { InitialState, NostoReducer } from "./NostoReducer";



export default function NostoProvider({ children }){
    const [ state, dispatch ] = useReducer(NostoReducer, InitialState);
    const [ global, _] = useContext(GlobalContext)

    return (       
        <NostoContext.Provider value={[ state, dispatch ]}>
            {children}
        </NostoContext.Provider>
    );
};
